import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../firebase/auth';
import { UserIcon, TicketIcon, ListIcon, CreditCardIcon, RightFromBracket, CartIcon } from './Icons/Icons';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import { auth } from '../firebase/firebase';

export default function Header({ handleShowToast }) {
  const { authUser, signOut } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(false);
  const mobileMenuId = 'primary-search-account-menu-mobile';

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = (link) => {
    setMobileMoreAnchorEl(false);
    if (typeof link === "string") {
      navigate(link);
    }
  };

  const handleMenuClose = (link) => {
    setAnchorEl(false);
    handleMobileMenuClose(link);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleSignOutClick = () => {
    signOut();
    handleMenuClose();
    navigate("/");
  };

  function startSelling() {
    setLoading(true);
    if (auth.currentUser) {
      navigate("/sellTickets/info");
      window.scrollTo(0, 0);
    } else {
      navigate("/login/info");
      handleShowToast("Sign up to start selling tickets", "warning");
      window.scrollTo(0, 0);
    }
    setLoading(false);
  }

  const BootstrapButton = styled(Button)({
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    borderRadius: '20px', // Rounded corners
    padding: '0.6rem 1.2rem',
  });

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      TransitionComponent={Fade}
    >
      <MenuItem onClick={() => handleMenuClose('/profile/account')}>
        <ListItemIcon><UserIcon /></ListItemIcon>
        <ListItemText>Account</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleMenuClose('/profile/mytickets')}>
        <ListItemIcon><TicketIcon /></ListItemIcon>
        <ListItemText>My Tickets</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleMenuClose('/profile/mylistings')}>
        <ListItemIcon><ListIcon /></ListItemIcon>
        <ListItemText>My Listings</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleMenuClose('/profile/payouts')}>
        <ListItemIcon><CreditCardIcon /></ListItemIcon>
        <ListItemText>Payments</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleSignOutClick}>
        <ListItemIcon><RightFromBracket /></ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      id={mobileMenuId}
      keepMounted
      open={isMobileMenuOpen}
      onClose={() => handleMobileMenuClose(null)}
      TransitionComponent={Fade}
    >
      <MenuItem onClick={() => handleMobileMenuClose('/discover')}>
        <p>Discover</p>
      </MenuItem>
      <MenuItem onClick={() => startSelling()}>
        <p>Start Selling</p>
      </MenuItem>
      {authUser ? (
        <div>
          <MenuItem onClick={() => handleMobileMenuClose('/cart')}>
            <p>Cart</p>
          </MenuItem>
          <MenuItem onClick={() => handleMobileMenuClose('/profile/account')}>
            <p>Profile</p>
          </MenuItem>
          <MenuItem onClick={handleSignOutClick}>
            <p>Log Out</p>
          </MenuItem>
        </div>
      ) : (
        <MenuItem onClick={() => handleMobileMenuClose('/login')}>
          <p>Sign In</p>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container maxWidth="lg">
        <AppBar
          position="static"
          sx={{
            boxShadow: 'none',
            borderRadius: "12px",
            backgroundColor: '#ffffff', // Set background to white
            padding: '0.5rem 0',
          }}
        >
          <Toolbar>
            {/* Mobile Menu Button */}
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenuOpen}
              sx={{ mr: 2, display: { xs: 'flex', md: 'none' }, color: "#8146F6" }}
            >
              <MenuIcon />
            </IconButton>

            {/* Logo */}
            <Typography
              variant="h6"
              component="a"
              href="/"
              sx={{
                flexGrow: 1,
                fontFamily: 'Lato',
                fontWeight: 700,
                fontSize: '1.6rem',
                color: '#8146F6', // Purple for the logo to maintain brand consistency
              }}
            >
              Entick
            </Typography>

            {/* Desktop Navigation */}
            {authUser ? (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <BootstrapButton href='/discover' sx={{ mr: 2, color: '#121212' }}>Discover</BootstrapButton>
                <IconButton size='small' href='/cart' sx={{ mr: 2, color: '#121212' }}>
                  <CartIcon />
                </IconButton>
                <IconButton size='small' onClick={handleProfileMenuOpen} sx={{ mr: 2, color: '#121212' }}>
                  <AccountCircle />
                </IconButton>
                <BootstrapButton
                  onClick={() => startSelling()}
                  disabled={loading}
                  variant="contained"
                  sx={{ backgroundColor: '#8146F6', color: 'white' }}
                >
                  Start Selling
                </BootstrapButton>
              </Box>
            ) : (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <BootstrapButton href='/discover' sx={{ mr: 3, color: '#121212' }}>Discover</BootstrapButton>
                <BootstrapButton href='/login' sx={{ mr: 3, color: '#121212' }}>Sign In</BootstrapButton>
                <BootstrapButton
                  onClick={() => startSelling()}
                  disabled={loading}
                  variant="contained"
                  sx={{ backgroundColor: '#8146F6', color: 'white' }}
                >
                  Start Selling
                </BootstrapButton>
              </Box>
            )}
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Container>
    </Box>
  );
}
