import React, { useState, } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import "./index.css"

import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Cart from './components/Cart/Cart';
import Checkout from './components/Checkout';
import ToastList from './components/ToastList/ToastList';
import SellerCheck from './components/SellerCheck';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import NotFound from './components/NotFound';
import { AuthUserProvider } from './firebase/auth';
import Skeleton from './components/Skeleton/Skeleton';
import { ThemeProvider, createTheme } from '@mui/material';
import { APIProvider } from "@vis.gl/react-google-maps";
import LoginFinish from './components/LoginFinish';
import CookieConsent from './components/CookieBanner';


const Discover = React.lazy(() => import('./components/Discover'));
const Event = React.lazy(() => import('./components/Event'));
const Profile = React.lazy(() => import('./components/Profile'));
const CreateEvent = React.lazy(() => import('./components/CreateEvent'));
const SellTickets = React.lazy(() => import('./components/Selling/SellTickets'));
const SellSetup = React.lazy(() => import('./components/Selling/SellingSetup'));
const Sell = React.lazy(() => import('./components/Sell'));

const root = ReactDOM.createRoot(document.getElementById('root')); 

export const config = {
  root_url: `${process.env.REACT_APP_ROOT_URL}`,
  return_url: `${process.env.REACT_APP_ROOT_URL}/sellerCheck`,
  checkout_url: `${process.env.REACT_APP_ROOT_URL}/checkout`,
  stripe_promise: `${process.env.REACT_APP_STRIPE_PROMISE}`,
  signIn_finish_url: `${process.env.REACT_APP_ROOT_URL}/loginfinish`,
  feedback_url: "https://entick.canny.io/feature-requests",
  places_API: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  algolia_API: `${process.env.REACT_APP_ALGOLIA_API}`,
  algolia_APP_ID: `${process.env.REACT_APP_ALGOLIA_APP_ID}`
}

function App() {
  // Toast States
  const [toasts, setToasts] = useState([]);
  const [autoCloseDuration, setAutoCloseDuration] = useState(5);
  const [position, setPosition] = useState("bottom-right");

  // Toast Functions
  const showToast = (message, type) => {
      const toast = {
          id: Date.now(),
          message,
          type,
        };
      
      setToasts((prevToasts) => [...prevToasts, toast]);
      
      setTimeout(() => {
        removeToast(toast.id);
      }, autoCloseDuration * 1000);
  }

  const removeToast = (id) => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // const removeAllToasts = () => {
  //     setToasts([]);
  // };
  
  // const handleAutoCloseDurationChange = (e) => {
  //     setAutoCloseDuration(Number(e.target.value));
  // };

  // const handlePositionChange = (event) => {
  //     setPosition(event.target.value);
  // };

//   // Modal States

// Colour palette for MUI library
const theme = createTheme({
  palette: {
    background: {
      paper: '#fff'
    },
    primary: {
      main: '#8146F6',
      contrastText: '#fff'
    },
    secondary:{
      main: '#25abef'
    }
  }
})
let searchClient;

//Searching
searchClient = algoliasearch(config.algolia_APP_ID, config.algolia_API);

const reload = () => window.location.reload();

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthUserProvider>
        <APIProvider
                apiKey={config.places_API}
                region='GB'
                solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
                >
          <div>
            <Header handleShowToast={showToast}/>
            <Routes>
            <Route path="/" element={
            <React.Suspense fallback={<Skeleton Type="circular" />}>
              <Discover searchClient={searchClient}/>
              </React.Suspense>} />

              <Route path="/discover" element={
              <React.Suspense fallback={<Skeleton Type="circular" />}>
                <Discover searchClient={searchClient}/>
                </React.Suspense>} />

              <Route path="/event/:id" element={<React.Suspense fallback={<Skeleton Type="circular" />}>
                <Event handleShowToast={showToast} />
                </React.Suspense>} />

              <Route path="/profile/:section" element={<React.Suspense fallback={<Skeleton Type="circular" />}>
                <Profile handleShowToast={showToast} />
              </React.Suspense>} />

              <Route path="/login" element={<Login handleShowToast={showToast}/>} />
              <Route path="/login/:redirect" element={<Login handleShowToast={showToast}/>} />
              <Route path="/admin/newevent" element={<React.Suspense fallback={<Skeleton Type="circular" />}>
                <CreateEvent handleShowToast={showToast}/>
              </React.Suspense>} />
              
              <Route path="/cart" element={<Cart handleShowToast={showToast}/>} />
              <Route path="/cart/checkout" element={<Checkout handleShowToast={showToast}/>} />
              <Route path="/sell" element={<React.Suspense fallback={<Skeleton Type="circular" />}>
              <Sell handleShowToast={showToast}/>
              </React.Suspense>} />

              <Route path="/sellTickets/:stage" element={
                <React.Suspense fallback={<Skeleton Type="circular"/>}>
              <SellTickets handleShowToast={showToast} searchClient={searchClient}/>
              </React.Suspense>} />

              <Route path="/sellTickets/:stage/:key" element={
                <React.Suspense fallback={<Skeleton Type="circular"/>}>
              <SellTickets handleShowToast={showToast}/>
              </React.Suspense>} />

              <Route path="/sellingSetup" element={
                <React.Suspense fallback={<Skeleton Type="circular"/>}>
              <SellSetup handleShowToast={showToast} />
              </React.Suspense>} />

              <Route path="/sellerCheck" element={<SellerCheck handleShowToast={showToast}/>} />
              <Route path="/loginfinish" element={<LoginFinish handleShowToast={showToast}/>} />
              
              <Route path="/terms" element={<Terms/>} />
              <Route path="/privacy" element={<Privacy/>} />
              <Route path="/apple-app-site-association" onEnter={reload} />
              <Route path="/.well-known/apple-developer-merchantid-domain-association" />
              <Route path="/.well-known/assetlinks.json" onEnter={reload} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer handleShowToast={showToast} />
          </div>
          <CookieConsent />
          <ToastList data={toasts} position={position} removeToast={removeToast} />
          </APIProvider>
        </AuthUserProvider>
      </ThemeProvider>
    </Router>
  )
}

root.render(
  <App/>
);